import { Form ,Button, Input,Spin, Modal} from "antd";
import {UserOutlined,LockOutlined } from '@ant-design/icons';
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useAuth } from "../context/auth";
import axios from "axios";
import { toast } from "react-toastify";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate,useLocation } from "react-router-dom";
axios.defaults.baseURL = process.env.REACT_APP_API


const {Item} = Form

const Login =()=>{
    const navigate = useNavigate()
    const location = useLocation()
    const [auth , setAuth] = useAuth()

    const [loader,setLoader] = useState(false)
    const [model,setModel] = useState(false)
    const [spin ,setSpin] = useState(false)
    const [otp , setOtp] = useState({status : false , email : ""})
    const [forgetForm] = Form.useForm()
    const [passwordVisible, setPasswordVisible] = useState(false);

    const onFinish = async (values)=>{
        try {
            const {data} = await axios.post("/auth/login",values)
            if(data.success)
            {
                toast.success(<p className="capitalize">{data.message}</p>)
                localStorage.setItem("auth",JSON.stringify(data))
                setAuth({...auth ,auth : true ,token : data.token})
                setTimeout(()=>{
                    navigate("/retailer/dashboard")
                },2000)
            }
        } catch (error) {
            toast.error(<p className="capitalize">{error.response.data.message}</p>)
        }
    }
    useEffect(()=>{
       let releaseTimer = setTimeout(()=>setLoader(false),2000)
       return ()=>{
        clearTimeout(releaseTimer)
       }
    },[])
    if(loader) return (
        <div className="min-h-screen flex items-center justify-center">
            <Spin size="large" />
        </div>
    )

    const forgetpass = ()=>{
        setModel(true)
    }

    const onForgetPassword = async (value)=>{
       try {
        setSpin(true)
        const {data} =  await axios.post("/forget" , value)
        if(data.success)
         toast.success(<p className="capitalize">{data.message}</p>)
         forgetForm.resetFields()
         setOtp({...otp ,email:data.email ,status:data.success})
       } catch (error) {
        toast.error(<p className="capitalize">{error.response.data.message}</p>)
       }
       finally{
        setSpin(false)
       }
    }

    const onForgetPasswordFinal = async (values)=>{
       try {
       setSpin(true) 
       const {data} =  await axios.put(`/forget/${otp&&otp.email}`,values)
       if(data.success)
       toast.success(<p className="capitalize">{data.message}</p>)

       } catch (error) {
        toast.error(<p className="capitalize">{error.response.data.message}</p>)
        
       }
       finally {
        setSpin(false)
        setModel(false)
       }
    }

    return(
        <div className="bg-gray-100 min-h-screen flex items-center justify-center">
            <ToastContainer />
            <div className="bg-white rounded-lg border p-8 flex flex-col gap-y-4   md:w-8/12 w-10/12 lg:w-4/12 animate__animated animate__zoomIn animate_faster">
                <div className="flex justify-center items-center ">
                    <h1 className="text-2xl font-semibold">Login</h1>
                </div>
                
                <Form onFinish={onFinish} autoComplete="off">
                    <Item 
                        name="email"
                        rules={[
                            {required:true,message: 'User name is required'},
                            {type: 'email', message: 'The input is not valid E-mail!'}
                        ]}
                        >
                        <Input size="large" style={{borderRadius:0}} placeholder="Email*" suffix={<UserOutlined style={{color: 'rgba(0,0,0,.30)',}}/>} />
                    </Item>
                    <Item 
                        name="password"
                        rules={[
                            {required:true,message: 'Password name is required'}
                        ]}
                    >
                        <Input.Password
                            size="large"
                            style={{borderRadius:0}}
                            placeholder="*********"
                            visibilityToggle={{
                                visible: passwordVisible,
                                onVisibleChange: setPasswordVisible,
                                }}
                        />
                    </Item>
                    <Item>
                        <Button
                            htmlType="submit" 
                            size="large"
                            style={{borderRadius:0}} className="w-full bg-indigo-900 text-white" >Login</Button>
                    </Item>
                </Form>
                <div className="md:flex gap-2">
                    <Link to="/register" className="w-full">
                        <Button
                            htmlType="text" 
                            size="smal"
                            style={{borderRadius:0}} className="w-full bg-indigo-900 text-white mb-2" >Signup</Button>
                    </Link>
                   
                        <Button
                            htmlType="text" 
                            size="smal"
                            onClick={()=>forgetpass()}
                            style={{borderRadius:0}} className="w-full bg-indigo-900 text-white">Forget Passwords</Button>
                    
                    
                </div>
            </div>
            <Modal
                title="Forget pasword"
                centered
                open={model}
                onCancel={() => setModel(false)}
                width={300}
                footer ={null}
                style={{ maxHeight: 700, overflowY: 'auto' }}
            >
              <Spin spinning={spin}>
                 {
                    !otp.status ? (  <Form onFinish={onForgetPassword} form={forgetForm}>
                        <Item 
                            name="email"
                            rules={[
                                {required:true,message: 'Your email is required'}
                            ]}
                            >
                            <Input size="large" type ="email" style={{borderRadius:0}} placeholder="Email*" suffix={<UserOutlined style={{color: 'rgba(0,0,0,.30)',}}/>} />
                        </Item>
    
                        <Item>
                            <Button htmlType="submit">Submit</Button>
                        </Item>
                        
                    </Form>) : ( 
                        <Spin spinning={spin}>
                        <Form onFinish={onForgetPasswordFinal} form={forgetForm}>
                    <Item 
                        name="otp"
                        rules={[
                            {required:true,message: 'Your otp is required'},
                            { pattern: /^\d{6}$/, message: 'Otp number must be 6 digits' }
                        ]}
                        >
                        <Input size="large" type ="number" style={{borderRadius:0}} placeholder="Enter yoyr 6 Digit Otp"  />
                    </Item>
                    <Item 
                        name="newpassword"
                        rules={[
                            {required:true,message: 'Your new password is required'}
                        ]}
                        >
                        <Input.Password
                                size="large"
                                style={{borderRadius:0}}
                                placeholder="*********"
                                visibilityToggle={{
                                    visible: passwordVisible,
                                    onVisibleChange: setPasswordVisible,
                                    }}
                            />
                    </Item>

                    <Item>
                        <Button htmlType="submit">Submit Otp</Button>
                    </Item>
                    
                </Form>
                </Spin>)
                 }
              </Spin>
            </Modal>
        </div>
       
    )
}

export default Login;